/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    /* width: 36px;
    height: 30px; */
    /* top: 5px; */
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #e3e3e3;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    position: absolute !important;
    top: 32px !important;
    right: 15px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #000000;
    height: 21px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: #ffffff !important;
    padding: 0;
    font-size: 1.15em;
    overflow-x: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}


/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
}

.menuItemSub {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    display: none;
    transition: 2s;
    animation: fadeIn 5s;
}

.menuLogoText {
    position: relative;
    top: -2vh;
}

.menu-itemCart {
    padding: 10px 15px;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    color: black;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    position: relative;
    top: -30px;
}

.menu-item:hover>.menuItemSub {
    display: flex;
}

@keyframes fadeIn {
    0% {
        display: none;
    }

    100% {
        display: flex;
    }
}

.react-burger-menu-btn{
    outline: none !important;
    border: none !important;
}

.react-burger-menu-btn:focus{
    outline: none !important;
    border: none !important;
}

.react-burger-menu-btn:active{
    outline: none !important;
    border: none !important;
}

.cartBtnMod{
    outline: none !important;
    
}

/* test */

.cartH2 {
    color: black;
    position: relative;
    top: 10px;
    left: 20px;
    font-size: 25px;
    margin: 20px auto;
    margin-bottom: 30px;
}

.emptyCart {
    width: 100% !important;
    height: auto !important;
    min-height: 400px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-evenly !important;
    flex-direction: column;
}

.emptyCart div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}


.emptyCart div i {
    font-size: 71px;
}

/* filled cart */

.filledCartSub {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 150px;
}

.recentlyViewedSubCart {
    width: 100%;
    height: auto;
    height: 130px;
    display: flex;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    background-color: white;
    border-top: 0.5px solid rgb(168, 168, 168);
    border-bottom: 0.5px solid rgb(168, 168, 168);
    padding-bottom: 10px;
    padding-top: 10px;
}

.recentlyViewProdCart {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10px;
    /* border: 1px solid black; */
}

.recentlyViewProdCart span {
    font-size: 15px;
    font-weight: 600;
}

.recenltViewImgCart {
    border-radius: 10px;
    width: 40%;
    height: 100%;
    /* background-image: url(../components/images/products/Aspirating-Syringe.jpg); */
    /* background-image: url("http://www.birds.com/wp-content/uploads/home/bird4.jpg") no-repeat center center fixed; */
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    /* border-radius: 10px; */
}

.toolsrecentViewCart {
    width: 100%;
    height: auto;
}

.toolsrecentViewCart i {
    margin-right: 15px;
    cursor: pointer;
}

.quantityShopCart {
    /* position: absolute;
    top: 53.5%;
    right: 15%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quantityShopCart span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantityShopCart span i {
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    padding: 6px;
    cursor: pointer;
    color: black;
    font-size: 15px;
}

.quantityShopCart span span {
    font-size: 15px;
    padding: 6px;
    color: black;
}

.trashBtn {
    color: black;
    position: relative;
    right: -70px;
    background: transparent !important;
    cursor: pointer;
}

/* checkout btn */
.checkoutCartDiv {
    width: 350px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid rgb(168, 168, 168);
    position: fixed;
    bottom: 0px;
    right: 0;
    background: white;
}

.subTotalDiv {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.subTotalDiv p {
    font-size: 21px;
    color: rgb(20, 20, 20);
    margin: 0px 25px;
    font-weight: 600;
}

.subTotalDiv span {
    margin: 0px 25px;
    font-size: 21px;
}

.checkoutBtn {
    width: 85%;
    height: auto;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 7px;
    color: white;
    background: rgb(48, 72, 182);
    border: none;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}