.searchBoxMain {
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.searchBox {
    width: 100%;
    height: auto;
    display: flex;
}

.searchBox input {
    width: 90%;
    height: 46px;
    font-size: 15px;
    padding-left: 20px;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 5px 0px 0px 5px;
}

.searchiconBox {
    width: 46px;
    height: 50px;
    color: #FFF;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    border-radius: 0px 5px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-top: 3px; */
}

.searchiconBox i {
    font-size: 21px;
}

.searchbarResults {
    width: 40%;
    max-width: 490px;
    height: auto;
    max-height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    position: relative;
    z-index: 99;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 120px;
}

.searchbarResultsSub {
    padding: 5px;
    color: black;
    width: 100%;
}

.searchBarSub {
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.searchResultDiv {
    width: 100%;
    height: 100px;
    display: flex;
    border-bottom: 1px solid grey;
}

.searchResultImgDiv {
    border-radius: 10px;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchResultDivImg {
    border-radius: 10px;
    width: 60%;
    height: 80%;
    /* background-image: url(../components/images/products/Aspirating-Syringe.jpg); */
    /* background-image: url("http://www.birds.com/wp-content/uploads/home/bird4.jpg") no-repeat center center fixed; */
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

.searchResultDet {
    width: 70%;
    height: 100px;
    display: flex;
    flex-direction: column;
}

.searchResultDetSpan {
    position: relative;
    top: 20px;
}

.viewAllDiv {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    padding: 10px 0px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.viewAllDiv:hover {
    color: rgb(44, 44, 44);
}

@media only screen and (max-width:600px) {
    .searchbarDiv {
        display: none;
    }

    .searchBarMain {
        display: none;
    }
}

@media only screen and (max-width:1000px) {
    .searchBoxMain {
        width: 30%;
    }
}

@media only screen and (max-width:750px) {
    .searchBoxMain {
        width: 40%;
    }
}