@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.productsMainDiv {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
}

.productsNav {
    width: 100%;
    max-width: 1222px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.browseMenu {
    width: 100vw;
    height: auto;
    max-width: 1222px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.browseMenuSub {
    width: 220px;
    height: auto;
    padding: 10px;
    background-color: rgb(66, 115, 175);
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    margin-left: 30px;
    border-radius: 0px 0px 10px 10px;
}

.browseDropDown {
    position: absolute;
    z-index: 4;
    top: 200px;
    width: 220px;
    height: auto;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(179, 179, 179);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    margin-left: 30px;
    /* margin-top: 5px; */
    border-radius: 10px 10px;
    padding: 0px 10px;
    display: none;
}

.browseMenu:hover .browseDropDown {
    transition: 1s;
    display: flex;
}

.browseMenuSub:active .browseDropDown {
    transition: 1s;
    display: flex;
}

.browseMenuSub:focus .browseDropDown {
    transition: 1s;
    display: flex;
}

.browseDropDown span {
    width: 100%;
    height: auto;
    border-bottom: 1px solid rgb(218, 218, 218);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    /* margin-left: 10px; */
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

.categorySub {
    position: absolute;
    left: 235px;
    z-index: 6;
    margin-top: -10px;
    width: auto;
    min-width: 200px;
    height: auto;
    background-color: white;
    visibility: hidden;
    transition: 0.2s;
}

.categorySub span {
    border-bottom: none;
    font-size: 12px !important;
    color: rgb(91, 91, 91);
}

.browseDropDown span:hover .categorySub {
    transition: 1s;
    visibility: visible;
}

.browseDropDown span i {
    font-weight: 200;
    font-family: "Poppins", sans-serif;
}

/* Products in shop */

/* Filter */
.productsDivv {
    width: 100%;
    height: auto;
    max-width: 1222px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
}

.productsFilter {
    width: 290px;
    height: auto;
    min-height: 400px;
    margin-left: 5px;
    margin-top: 60px;
    border-radius: 10px;
    padding-bottom: 20px;
    position: sticky !important;
    top: 50px !important;
}

.productsFilter form {
    width: 100%;
    height: auto;
    min-height: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.877);
    border-radius: 10px;
}

.filterHeading {
    width: 100%;
    background-color: rgb(66, 115, 175);
    /* background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%); */
    color: white;
    text-align: center;
    border-radius: 10px 10px 0px 0px;
}

.filterHeading h2 {
    font-size: 19px;
    font-family: "Montserrat", sans-serif;
    padding: 15px;
}

.recentlyViewedDiv {
    width: 100%;
    height: auto;
    /* min-height: 600px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

/* @media only screen and (max-width:600px) {
    .recentlyViewedDiv {
        display: none;
    }
} */

.recentlyViewedSub {
    width: 90%;
    height: auto;
    min-height: 170px;
    display: flex;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 10px;
    background-color: white;
    margin: 20px auto;
}

.recentlyViewProd {
    width: 100%;
    height: auto;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-top: 15px;
    padding-right: 5px;
}

.recenltViewImg {
    border-radius: 10px;
    width: 40%;
    max-width: 170px;
    min-width: 150px;
    height: 100%;
    /* background-image: url(../components/images/products/Aspirating-Syringe.jpg); */
    /* background-image: url("http://www.birds.com/wp-content/uploads/home/bird4.jpg") no-repeat center center fixed; */
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    /* border-radius: 10px; */
}

.toolsrecentView {
    width: 100%;
    height: auto;
}

.toolsrecentView i {
    margin-right: 15px;
    cursor: pointer;
}

.productCat {
    width: 100%;
    height: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* border-bottom: 1px solid rgb(184, 184, 184); */
}

.productCat h6 {
    margin-left: 14px;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
}

.productCat select {
    width: 90%;
    padding: 5px;
    display: block;
    margin: 0px auto;
    border-radius: 5px;
    position: relative;
}

.productBrand {
    width: 100%;
    height: auto;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* border-bottom: 1px solid rgb(184, 184, 184); */
}

.productBrand h6 {
    margin-left: 14px;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
}

.productBrand select {
    width: 90%;
    padding: 5px;
    display: block;
    margin: 0px auto;
    border-radius: 5px;
    position: relative;
}

.rangeSliderDiv {
    width: 100%;
}

.rangeSliderDiv h6 {
    margin-left: 14px;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
}

.rangeVal {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rangeVal span {
    margin: 10px 20px;
}

/* Yellow Range Slider */

.range-slider {
    width: 80% !important;
    margin: 24px auto 0;
}

#range-slider-yellow {
    background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E") #333;
}

#range-slider-yellow .range-slider__range {
    background: rgb(66, 115, 175);
    transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
    width: 25px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    transition: transform 0.3s;
    cursor: ew-resize;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#range-slider-yellow .range-slider__thumb[data-active] {
    transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
    height: 16px;
}

.applyFilter {
    width: 100px;
    height: auto;
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: rgb(66, 115, 175);
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    margin: 10px auto;
}

.productsDisplayDiv {
    width: 72%;
    height: auto;
    min-height: 400px;
    /* border: 1px solid black; */
    margin-top: 60px;
}

.productDisplaySub1 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -10px;
}

.productDisplaySub1 h2 {
    font-size: 28px;
    margin: 10px 15px;
}

.productDisplaySub1 select {
    width: auto;
    min-width: 100px;
    padding: 5px;
    /* background: none;
    border: none;
    border-bottom: 2px solid black; */
    margin: 10px 15px;
    border-radius: 3px;
    outline: none;
    font-size: 15px;
}

.productDisplaySub2 {
    width: 100%;
    height: auto;
}

.productDisplaySub2 h1{
    text-align: center;
    margin: 10px;
}

.productsPagediv {
    width: 100%;
    height: auto;
}

.featuredPageProducts {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.productsPageSubDiv {
    width: 260px;
    height: auto;
    margin: 15px auto;
    background-color: white;
    padding-bottom: 15px;
    border-radius: 12px;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.productsPageSubDiv:hover {
    transform: scale(1.05);
}

.toolsProductsPage {
    float: right;
    display: flex;
    width: 20px;
    height: auto;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    /* display: none; */
    transition: 1s;
}

.toolsProductsPage i {
    margin: 10px;
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
    color: rgb(43, 43, 43);
}

.loadMoreDiv {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadMoreDiv button {
    width: 150px;
    height: 60px;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    background-color: black;
    color: white;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-top: 50px;
    cursor: pointer;
    animation-name: appear;
    animation-duration: 5s;
}

@keyframes appear {
    from {
        background-color: rgba(0, 0, 0, 0);
        color: rgba(255, 255, 255, 0);
        border: none;
    }

    to {
        background-color: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
    }
}

/* animation idea... left to right margin give bass animation mein */

.sortByDiv p {
    width: 150px;
    height: auto;
    padding: 7px 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-right: 20px;
}

.sortOptions {
    position: absolute;
    z-index: 2;
    width: 150px;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    top: 45px;
    border-radius: 5px;
}

.sortOptions span {
    width: 100%;
    padding: 7px 10px;
    transition: 0.5s;
    cursor: pointer;
}

.sortOptions span:hover {
    width: 100%;
    background-color: #33333333;
}


@media only screen and (max-width:850px) {
    .productsFilter {
        width: 250px;
    }
}

@media only screen and (max-width:730px) {
    .productsFilter {
        width: 350px;
        margin-left: 20px;
    }
}

@media only screen and (max-width:600px) {
    .productsFilter {
        width: 300px;
        margin-left: 20px;
    }
}

@media only screen and (max-width:550px) {
    .productsFilter {
        display: none;
    }
    .productsDisplayDiv{
        width: 100%;
    }
}