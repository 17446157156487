@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

html {
    scroll-behavior: smooth;
}

body {
    width: 100vw;
    height: auto;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.section1Mainmost {
    width: 100vw;
    height: auto;
    min-height: 110vh;
    background-color: transparent;
    /* background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%); */
}

.topBarOne p {
    margin-top: 10px;
}

/* *{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    box-sizing: border-box;
} */

/* top blue bar */
.topBarMain {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBar {
    width: 100%;
    max-width: 1222px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topBarTwo i {
    padding: 10px;
    cursor: pointer;
}

.topBarTwo i:hover {
    color: rgb(255, 255, 255);
}

/* Navbar */
.navbarMain {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navBar {
    background-color: white;
    width: 100%;
    max-width: 1222px;
    height: auto;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 15px;
}

.logoNav {
    max-width: 100px;
    height: auto;
    cursor: pointer;
}

.logoMaintext {
    color: rgb(49, 49, 49);
    font-size: 24px;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.sidebar {
    position: static !important;
}

.navAccBox {
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 20px;
}

.navAccBox i {
    font-size: 21px;
    cursor: pointer;
}

.superTxt {
    position: relative;
    top: -10px;
    left: -10px;
    font-size: 10px;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    color: white;
    padding: 2px 7px;
    border-radius: 50%;
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.userIcon {
    margin-right: 15px;
}

.secOneMain {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.secOne {
    width: 100%;
    max-width: 1232px;
    height: auto;
    /* max-height: 500px; */
    display: flex;
    flex-direction: row;
}

.secOneSub {
    width: 50%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secOneSub2 {
    width: 50%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    top: -50px;
}

.secOneSub2 h2 {
    font-size: 72px;
    line-height: 82px;
    font-family: "Hanken Grotesk", sans-serif;
    color: rgb(41, 41, 41);
    font-weight: 500;
    position: relative;
    top: 50px;
}

.subTxt {
    font-size: 21px;
    font-weight: 700;
    font-family: "Hanken Grotesk", sans-serif;
    position: relative;
    color: rgb(41, 41, 41);
    left: 8px;
}

.btnDiv {
    width: 325px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 10px;
    top: 50px;
}

.btnDiv button {
    width: 150px;
    height: auto;
    padding: 14px 18px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: rgb(29, 143, 236);
    border: 2px solid rgb(255, 255, 255);
    font-family: "Hanken Grotesk", sans-serif;
    cursor: pointer;
    font-weight: 800;
}

.aboutBtn {
    width: 150px !important;
    border: 2px solid rgb(29, 143, 236) !important;
    background-color: white !important;
    color: rgb(29, 143, 236) !important;
}

.btnDiv button:hover {
    color: white !important;
    border: none;
    border: 2px solid rgb(65, 145, 211) !important;
    background-color: rgb(65, 145, 211) !important;
}

.aboutBtn:hover {
    color: white !important;
    border: none;
    border: 2px solid rgb(29, 143, 236) !important;
    background-color: rgb(29, 143, 236) !important;
}


/* Media frames */


@media only screen and (max-width: 1300px) {
    .topBar {
        width: 95%;
    }

    .navBar {
        width: 95%;
    }
}

@media only screen and (max-width: 1100px) {
    .logoMaintext {
        font-size: 20px;
    }

    .logoNav {
        max-width: 90px;
    }
}

@media only screen and (max-width: 1000px) {
    .navAccBox {
        width: 120px;
    }

    .secOneSub2 h2 {
        font-size: 58px;
    }

    .logoNav {
        max-width: 80px;
    }

    .navAccBox {
        width: 100px;
    }
}

@media only screen and (max-width: 800px) {
    .navAccBox {
        width: 100px;
    }

    .logoMaintext {
        font-size: 20px;
        position: relative;
        top: -20px;
    }

    .logoNav {
        max-width: 70px;
    }

    .navBar {
        width: 98%;
    }

    .navAccBox {
        width: 80px;
    }

    .logoMaintext {
        font-size: 18px;
        top: 0px;
    }
}

@media only screen and (max-width:750px) {
    .navAccBox {
        display: none;
    }

    .navBar {
        display: none;
    }
}

@media only screen and (max-width: 850px) {
    .secOneSub2 h2 {
        font-size: 51px;
    }
}

@media only screen and (max-width: 650px) {
    .secOneSub {
        width: 100%;
        position: absolute;
        z-index: inherit;
    }

    .secOneSub2 {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .navBar {
        justify-content: space-between;
    }

    .navAccBox {
        padding-left: 20px;
        padding-right: 20px;
    }

    .searchBox {
        display: none;
    }

    .topBarTwo {
        display: none;
    }

    .topBar {
        align-items: center;
        justify-content: center;
    }
}

/* Navbar Mobile */

.navBarMobile {
    background-color: white;
    width: 95%;
    max-width: 1222px;
    height: auto;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 15px;
}

.logoNavMobile {
    max-width: 100px;
    height: auto;
    cursor: pointer;
}

.logoMaintextMobile {
    color: rgb(49, 49, 49);
    font-size: 22px;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.menuNav {
    font-size: 27px;
    position: absolute !important;
    top: 76px;
    left: 30px;
    cursor: pointer;
}

@media only screen and (min-width:750px) {
    .navBarMobile {
        display: none;
    }
}

@media only screen and (max-width:500px) {
    .logoMaintextMobile {
        font-size: 20px;
    }
}

@media only screen and (max-width:460px) {
    .logoMaintextMobile {
        font-size: 18px;
        margin-right: 10px;
    }

    .navBarMobile {
        justify-content: flex-end;
    }

    .logoNavMob {
        width: 70px;
    }

    .menuNav {
        left: 20px;
    }
}

/* Attributes section */

.attributesSectionMain {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attributesSection {
    width: 100%;
    max-width: 1200px;
    height: auto;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: rgb(6, 17, 32);
    color: rgb(255, 255, 255);
    border-radius: 12px;
    position: relative;
    top: -35px;
}

.attributeShopDiv{
    width: 100%;
}

.attributeSub {
    width: 23%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.attributeSub i {
    font-size: 35px;
    color: #34c5ff;
}

.attributeSub span {
    font-size: 15px;
    position: relative;
    top: 20px;
    font-family: "Montserrat", sans-serif;
    /* font-family: 'SF Pro Display', sans-serif; */
    font-weight: 600;
}


/* Media frames */

@media only screen and (max-width: 1050px) {
    .attributesSection {
        width: 90%;
        top: -50px;
    }
}

@media only screen and (max-width: 840px) {
    .attributesSection {
        width: 90%;
        top: -20px;
    }
}


@media only screen and (max-width:650px) {

    .attributeSub {
        width: 50%;
    }

}

/* Quote section */

.quoteMain {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quoteSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.q1 {
    font-size: 3.2rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-top: 60px;
    color: rgb(20, 20, 20);
    /* background: -webkit-linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.q2 {
    font-size: 2rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 60px;
    color: rgb(20, 20, 20);
    /* background: -webkit-linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

@media only screen and (max-width: 1000px) {
    .quoteSection {
        width: 90%;
    }
}

/* Section 2 */

.section2Main {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
}

.section2 {
    width: 100%;
    max-width: 1222px;
}

.section2 h2 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 51px;
    color: rgb(20, 20, 20);
    margin: 50px;
}

.featuredProducts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 20px; */
}

.productsDiv {
    width: 22%;
    height: auto;
    /* min-width: 200px; */
    min-height: 320px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    transition: 0.5s;
    cursor: pointer;
}

.productsDiv:hover {
    transform: scale(1.05);
}

.bgImgProducts {
    width: 100%;
    height: auto;
    min-height: 200px;
    /* background-image: url(../components/images/products/Aspirating-Syringe.jpg); */
    /* background-image: url("http://www.birds.com/wp-content/uploads/home/bird4.jpg") no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
}

.discountProducts {
    border: none;
    padding: 7px;
    margin: 10px 0px;
    background: rgb(60, 60, 193);
    /* border-radius: 5px; */
    color: white;
}

.toolsProducts {
    float: right;
    display: flex;
    width: 20px;
    height: auto;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    display: none;
    transition: 1s;
}

.productsDiv:hover .toolsProducts {
    transition: 1s;
    display: flex;
}

.toolsProducts i {
    margin: 10px;
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
    color: rgb(43, 43, 43);
}

.detailsProducts {
    width: 100%;
    height: 90px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    word-wrap: break-word;
}

.moreBtn {
    width: auto;
    width: 200px;
    padding: 20px;
    margin: 25px auto;
    margin-bottom: 50px;
    display: block;
    border-radius: 12px;
    font-weight: 600;
    font-size: 18px;
    border: 3px solid rgb(29, 143, 236) !important;
    background-color: white !important;
    color: rgb(29, 143, 236) !important;
    text-decoration: none;
    text-align: center;
    font-weight: 800;
}

@media only screen and (max-width: 1250px) {
    .featuredProducts {
        padding: 0px;
    }

    .productsDiv {
        width: 30vw;
    }
}

@media only screen and (max-width: 950px) {
    .productsDiv {
        width: 40vw;
    }
}

@media only screen and (max-width: 700px) {
    .section2 h2 {
        font-size: 40px;
    }

    .productsDiv {
        width: 45vw;
        min-height: 320px;
    }
}

@media only screen and (max-width: 450px) {

    .productsDiv {
        width: 80vw;
        min-height: 320px;
    }
}

/* Track your order */
.orderTrackMain {
    width: 100vw;
    height: auto;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderTrackDiv {
    width: 100%;
    max-width: 1200px;
    height: auto;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.orderInputs {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderInputs input {
    width: 250px;
    height: auto;
    margin: 20px 10px;
    padding: 10px;
    font-size: 17px;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(146, 205, 223);
    border-radius: 5px;
}

.trackBtn {
    width: 120px;
    height: auto;
    padding: 15px 10px;
    border-radius: 10px;
    border: none;
    color: white;
    font-family: "Montserrat", sans-serif;
    margin-top: 50px;
    margin-bottom: 90px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
}

/* testimonial  */

.testiMonialsMain {
    width: 100vw;
    height: auto;
    /* min-height: 100vh; */
    /* max-height: 900px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);

    border-bottom: 5px solid white;
}

.testimonialsDiv {
    width: 100%;
    height: auto;
    max-width: 1222px;
    /* min-height: 100vh; */
    /* max-height: 900px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.reviewsDiv {
    width: 100%;
    height: auto;
    /* min-height: 500px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    /* top: 25px; */
}

.reviewHeading {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 51px;
    color: rgb(20, 20, 20);
    margin-top: 40px;
}

.tracktxt {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
    margin-top: 20px;
    text-align: center;
    padding: 10px;
}

.reviewSub {
    background-color: white;
    padding: 10px;
    width: 30%;
    height: auto;
    min-height: 300px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transition: 0.5s;
    margin-top: 30px;
    margin-bottom: 30px;
}

.reviewSub:hover {
    transform: scale(1.02);
}

.reviewStars {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
}

.reviewStars i {
    margin: auto 2px;
    color: gold;
    font-size: 21px;
}

.reviewTxt {
    font-size: 17px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    padding: 10px;
}


.reviewPerson {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.humanIcon {
    width: 50px;
    height: auto;
    border-radius: 50%;
    border: 2px solid black;
    margin-right: 20px;
}

.personDetails {
    display: flex;
    flex-direction: column;
    position: relative;
    left: -17px;
}

.reviewName {
    font-size: 21px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-left: 20px;
    /* position: relative;
    top: -40px; */
}

.reviewPosition {
    font-size: 15px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin-left: 20px;
}

.moreRev {
    width: auto;
    height: 60px;
    font-size: 19px;
    font-family: "Montserrat", sans-serif;
    /* background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%); */
    background-color: white;
    border: 1px solid white;
    font-weight: 800;
    border-radius: 5px;
    cursor: pointer;
    /* position: relative; */
    /* top: -40px; */
    margin-top: 30px;
    margin-bottom: 60px;
    transition: 0.5s;
    padding: 10px 30px;
}

.moreRev:hover {
    border: 1px solid white;
    color: white;
    background: #000;
}

@media only screen and (max-width: 850px) {
    .reviewSub {
        width: 300px;
    }
}

@media only screen and (max-width: 750px) {
    .reviewSub {
        width: 250px;
    }

    .orderInputs {
        flex-direction: column;
    }
}

@media only screen and (max-width: 600px) {
    .reviewSub {
        width: 80%;
    }
}