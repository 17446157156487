.aboutMain {
    width: 100vw;
    height: auto;
    min-height: 95vh;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
}

.aboutCntxtMain {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aboutCntxt {
    width: 100%;
    max-width: 1222px;
    height: auto;
    display: flex;
}

.abtImgDiv {
    width: 30%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.abtContentDiv {
    width: 70%;
    height: auto;
    padding: 10px;
}

.abtContentDiv h2 {
    color: #333;
    font-size: 41px;
    font-family: "Montserrat", sans-serif;
}

.abtContentDiv p {
    color: #333;
    font-family: "Montserrat", sans-serif;
    padding: 10px;
    line-height: 25px;
    /* font-weight: 500; */
}

/* Privacy Policy */


.privacyMain {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 10px 15px; */
    margin-bottom: 20px;
}

.privacyContext {
    width: 90%;
    max-width: 1222px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.privacyContext h2 {
    color: #333;
    font-size: 41px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    margin: 50px auto;
}

.privacyContext h4 {
    color: #333;
    /* font-size: 41px; */
    font-family: "Montserrat", sans-serif;
    /* text-align: center; */
}

.privacyContext span {
    font-family: "Montserrat", sans-serif;
    color: #333;
    line-height: 25px;
    margin-bottom: 5px;
}

.privacyContext p {
    color: #333;
    font-family: "Montserrat", sans-serif;
    /* padding: 2px; */
    line-height: 25px;
    /* font-weight: 500; */
}

.privacyContext ol {
    color: #333;
    font-family: "Montserrat", sans-serif;
    /* padding: 2px; */
    line-height: 25px;
    /* font-weight: 500; */
}

.privacyContext ul {
    color: #333;
    font-family: "Montserrat", sans-serif;
    /* padding: 2px; */
    line-height: 25px;
    /* font-weight: 500; */
}