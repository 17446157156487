.contactMain {
    width: 100vw;
    height: 190px;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
}

.contactHeading{
    font-family: "Montserrat", sans-serif;
    font-size: 51px;
    margin: 50px auto;
}

.partFour {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    /* padding: 20px; */
}

.contactP {
    color: black !important;
}

.contactUsDiv {
    width: 100%;
    max-width: 1440px;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPartOneMain {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contactPartOne {
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.contactPartOneSub {
    width: 90%;
    max-width: 500px;
    height: 100px;
    background-color: white;
    margin: 10px 2%;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* padding: auto 10px; */
}

.contactPartOneSub i {
    width: 20%;
    height: auto;
    position: relative;
    bottom: -10px;
    font-size: 30px;
    text-align: center;
    background: -webkit-linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contactPartOneSub div {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

/* .contactPartOneSub div p {
    margin-top: -1px;
    margin-bottom: -2px;
} */

.contactPartOneSub div span {
    margin-top: -1px;
    margin-bottom: -2px;
}

.contactForm {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    padding: 20px;
    border-radius: 10px;
}

.contactForm h4 {
    font-size: 41px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    margin-bottom: 15px;
    margin-left: 20px;
    color: white;
}

.inputsDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-evenly;
    font-family: 'Montserrat', sans-serif;
}

.inputsDivOne {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.inputsDivOneSub {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.inputsDivOneSubMsg {
    display: none;
}

.inputsDivOneSub p {
    /* margin-bottom: -1px; */
    font-weight: 600;
    color: white !important;
}

.inputsDivOneSub div {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.inputsDivOneSub div input {
    width: 80%;
    border: none;
    outline: none;
    margin-left: 12px;
    font-weight: 600;
}

.inputsDivOneSub div textarea {
    width: 90%;
    border: none;
    outline: none;
    margin-left: 12px;
    font-weight: 600;
    display: none;
}

.contactPartTwo {
    width: 90%;
    height: auto;
    min-height: 60vh;
    border-radius: 10px;
    margin: 5% auto;
}

.inputsDivTwo{
    width: 60%;
    position: relative;
    right: -40px;
}

.inputsDivTwo textarea {
    width: 80%;
    height: 230px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 600;
    margin-left: 10px;
    padding: 10px;
}

.inputsDivTwo p {
    /* margin-bottom: 1px; */
    font-weight: 600;
    color: white !important;
    margin-left: 10px;
}

.submitContact {
    width: 200px;
    height: auto;
    padding: 10px;
    color: rgb(0, 0, 0);
    background-color: #000000;
    color: white;
    border-radius: 10px;
    outline: none;
    font-weight: bolder;
    font-size: 21px;
    border: none;
}

@media screen and (max-width: 1150px) {
    .contactPartOneMain {
        flex-direction: column-reverse;
    }

    .contactPartOne {
        width: 95%;
        flex-direction: row;
        justify-content: space-between;
    }

    .contactPartOneSub {
        width: 30%;
        height: 20vh;
        justify-content: space-evenly;
        margin: 10px 10px;
    }

    .contactPartOneSub i {
        width: 20%;
        height: auto;
        font-size: auto;
    }

    .contactPartOneSub div {
        width: 80%;
        height: 100%;
    }

    .contactForm {
        width: 90%;
    }

    .contactForm h4 {
        font-size: 31px;
    }
}

@media screen and (max-width: 865px) {
    .contactPartOne {
        width: 95%;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .contactPartOneSub {
        border: 1px solid grey;
        border-radius: 10px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {

    .contactForm {
        width: 100vw;
        padding: 5px auto;
        border-radius: 0px;
    }

    .inputsDiv {
        justify-content: space-between;
    }

    .inputsDivOneSub div {
        width: 100%;
        background-color: rgb(255, 255, 255);
        padding: 10px 7px;
        border-radius: 10px;
    }

    .inputsDivOneSub div input {
        width: 90%;
        border: none;
        outline: none;
        margin-left: 2px;
        font-weight: 600;
    }

}

@media screen and (max-width: 500px) {

    .inputsDivOneSub div input {
        width: 90%;
        border: none;
        outline: none;
        margin-left: 12px;
        font-weight: 600;
    }

    .inputsDivOneSub div textarea {
        width: 90%;
        border: none;
        outline: none;
        margin-left: 12px;
        font-weight: 600;
        display: block;
    }

    .inputsDivTwo {
        display: none;
    }

    .inputsDivOneSubMsg {
        display: block;
    }

    .inputsDivOne {
        width: 100%;
        justify-content: center;
    }
}