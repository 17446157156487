.checkoutMain {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: "Montserrat", sans-serif;
}

.checkoutSec1 {
    width: 100vw;
    max-width: 1222px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.checkOutLogoDiv {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.checkOutLogoDiv img {
    width: 100px;
}

.checkOutLogoDiv h3 {
    font-size: 25px;
    font-family: "MONTSERRAT", sans-serif;
    margin-left: 15px;
    color: rgb(48, 48, 48);
}

.checkoutContactDiv {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    top: 10px;
}

.checkoutContactDiv span {
    margin-right: 20px;
    font-size: 15px;
}

.checkoutContactDiv span i {
    margin-right: 10px;
}

.numberSpan {
    /* position: relative; */
    /* left: -165px; */
    margin-bottom: 10px;
}

/* checkoutSec2 */

.checkoutSec2 {
    width: 100%;
    height: auto;
    min-height: 80vh;
    max-width: 1222px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 20px;
}

.checkoutSec2Sub1Main {
    width: 68%;
    height: auto;
    /* min-height: 80vh; */
    display: flex;
    flex-direction: column;
    /* background-color: rgb(233, 233, 233); */
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
}

.checkoutSec2Sub1 {
    width: 100%;
    height: auto;
    /* min-height: 500px; */
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    background-color: rgb(235, 235, 235);
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
    /* padding-bottom: 20px; */
}

.checkoutSec2Sub1 span {
    width: 100%;
    background-color: rgb(43, 43, 43);
    /* background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%); */
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
}

.checkoutSec2Sub1 span h3 {
    margin-left: 20px;
}

.checkoutSec2Sub2Main {
    width: 28%;
    height: auto;
    /* background-color: rgb(233, 233, 233); */
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.checkoutSec2Sub2 {
    width: 100%;
    height: auto;
    background-color: rgb(233, 233, 233);
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.shippingInputs {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.shippingInputs input {
    width: 90%;
    height: 16px;
    padding: 15px;
    font-size: 17px;
    margin: 10px auto;
    border-radius: 12px;
    border: 1px;
}

.shippingInputs div input {
    width: 40%;
    height: 16px;
    padding: 15px;
    font-size: 17px;
    margin: 10px auto;
    border-radius: 12px;
    border: 1px;
}

.shippingInputs div {
    width: 100%;
    height: auto;
    display: flex;
}

.checkoutAddressDiv {
    width: 100%;
    height: auto;
    display: flex;
}

.checkoutAddressDiv select {
    width: 25%;
    height: auto;
    padding-left: 10px;
    color: grey;
    /* padding: 19px; */
    font-size: 17px;
    margin: 10px auto;
    border-radius: 12px;
    border: none;
}

.checkoutAddressDiv input {
    width: 25% !important;
    height: 16px !important;
    padding: 15px !important;
    font-size: 17px !important;
    margin: 10px auto !important;
    border-radius: 12px !important;
}

.checkoutSec2Sub1Span {
    width: 100%;
    background-color: rgb(43, 43, 43);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    border-radius: 10px 10px 0px 0px;
}

.checkoutSec2Sub2 span h3 {
    margin-left: 20px;
    padding: 10px;
}

.trashIcon {
    position: relative;
    right: -120px;
    top: -10px;
}

.summaryDiv {
    width: 100%;
    height: auto;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 10px;
}

.summaryDiv div {
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.summaryDiv div {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
}

.summaryDivTotal span {
    font-size: 18px;
    font-weight: 600;
}

/* Radio toggle downs */
.toggleRadioInput {
    /* Hide original inputs */
    width: 20px;
    height: 20px;
    accent-color: #333;
}

.toggleDownDiv {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.togglebtn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 15px 0px;
    font-size: 17px;
    font-weight: 500;
}

.togglebtn input {
    margin: 0px 20px;
    position: relative;
    top: 1px;
}

.toggleDown {
    width: 100%;
    height: auto;
    cursor: pointer;
    /* border: 0.2px solid rgb(175, 175, 175); */
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    border-radius: 0px 0px 10px 10px;
}

.toggleDown p {
    margin-left: 15px;
    padding: 10px;
}

#toggleTextHidden {
    display: none;
    transition: 1s ease-in-out;
}

#toggleText {
    display: flex;
    transition: 1s ease-in-out;
}

/* #toggleLabel:active + #toggleText {} */

/* Submit button */

.checkoutSubmit {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto;
}

.checkoutSubmit p {
    color: rgb(2, 142, 197);
    font-size: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.checkoutSubmit button {
    width: 200px;
    padding: 20px 0px;
    font-size: 18px;
    background-color: #333;
    color: white;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
}

/* Scrollbar 👇🏻 */
.recentlyViewedDiv::-webkit-scrollbar-thumb {
    background: rgb(43, 43, 43);
}

/* Responsiveness */
@media screen and (max-width: 1100px) {

    .checkoutSec2 {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .checkoutSec2Sub1Main {
        width: 90%;
    }

    .checkoutSec2Sub2Main {
        width: 90%;
    }

    .shippingInputs {}

    .trashIcon {
        right: -80%;
    }
}

@media screen and (max-width: 700px) {

    .checkoutContactDiv {
        display: none;
    }

    .checkOutLogoDiv {
        width: 100%;
    }

}

@media screen and (max-width: 400px) {

    .checkOutLogoDiv img {
        width: 100px;
    }

    .checkOutLogoDiv h3 {
        font-size: 21px;
        font-family: "MONTSERRAT", sans-serif;
        margin-left: 15px;
        color: rgb(48, 48, 48);
    }

}