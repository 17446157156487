/* Our partners 😎 */

.sliderDivMainMost {
    width: 95vw;
    height: auto;
    min-height: 20vh;
    max-width: 1200px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    /* position: relative; */
    /* bottom: -70px; */
    margin-bottom: 70px;
}

.sliderDivMainMost h3 {
    font-size: 39px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.partnerDIvMain {
    width: 90%;
    display: block;
    margin: 10px 5px;
    cursor: pointer;
}

.sliderBtns {
    padding: 10px;
    cursor: pointer;
    background: -webkit-linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.partnersDiv img {
    width: 200px;
    height: auto;
    margin: 5px auto;
    cursor: pointer;
}

.dummyGap {
    width: 100vw;
    height: 10vh;
    min-height: 100px;
}

/* Dotte dlines */

.dotted {
    width: 90%;
    border: 1px dashed black;
    background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
}

@media screen and (max-width: 950px) {
    .testismonialMainDiv h1 {
        font-size: 48px;
    }
}

@media screen and (max-width: 1100px) {
    .partnersDiv img {
        width: 20vw;
    }

    .sliderDivMainMost h3 {
        font-size: 40px;
    }

}

@media screen and (max-width: 900px) {
    .testimonialDiv {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .testismonialMainDiv h1 {
        font-size: 45px;
    }

    .sliderDivMainMost h3 {
        font-size: 30px;
    }

}

.sliderDivMainMostInc {
    margin: 50px auto;
}